@media (max-width: 767px) {
	.materials {
		height: auto;
		flex-wrap: wrap;
		flex-direction: column;
		&__row-2 {
    		width: 100%; }
		.j-space {
			flex-direction: row; } }
	.materials__item-5 .materials__block,
	.materials__item-4 .materials__block,
	.materials__item-3 .materials__block,
	.materials__item-2 .materials__block,
	.materials__item-1 .materials__block {
		margin-bottom: 10px; }

	.materials__item-1 .materials__block-border {
		justify-content: flex-start; } }


