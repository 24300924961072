* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0; }

.materials {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  height: 700px;
  color: #fff; }
  .materials .j-space {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .materials__row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .materials__block {
    padding: 10px;
    position: relative;
    overflow: hidden;
    width: calc(100% - 15px);
    height: calc(100% - 10px); }
    .materials__block:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: rgba(39, 157, 140, 0.6);
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s; }
  .materials__row-2 {
    width: 50%; }
  .materials__block:hover:before {
    background: rgba(39, 157, 140, 0.1); }
  .materials__block-border {
    border: 1px solid #fff;
    position: relative;
    z-index: 1;
    height: 100%;
    padding: 10px;
    font-size: 14px; }
    .materials__block-border a {
      color: #fff;
      text-decoration: none; }
    .materials__block-border ul {
      margin-bottom: 15px;
      list-style: none; }
      .materials__block-border ul a {
        display: inline-block;
        margin-bottom: 5px; }
      .materials__block-border ul a:before {
        content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNui8sowAAAAWdEVYdENyZWF0aW9uIFRpbWUAMDMvMTYvMjAFCOkTAAAALUlEQVQImV3MQQoAIAwDwWnx/1+OF8XinkKWpJI4BAXtUUdYNwyy7mRe9F/ABmDpCQrub9EFAAAAAElFTkSuQmCC");
        display: inline-block;
        margin-right: 3px;
        position: relative;
        top: -3px; }
  .materials__bg {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 100%;
    min-height: 100%;
    min-width: 100%; }
  .materials__item-3 .materials__block {
    margin-bottom: 10px; }
  .materials__title {
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 15px;
    text-shadow: 1px 2px 1px #737373; }
  .materials__item-2, .materials__item-5 {
    height: 60%; }
  .materials__item-3, .materials__item-4 {
    height: 40%; }
  .materials__item-1 .materials__block-border {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end; }

@media (max-width: 767px) {
  .materials {
    height: auto;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .materials__row-2 {
      width: 100%; }
    .materials .j-space {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row; }
  .materials__item-5 .materials__block,
  .materials__item-4 .materials__block,
  .materials__item-3 .materials__block,
  .materials__item-2 .materials__block,
  .materials__item-1 .materials__block {
    margin-bottom: 10px; }
  .materials__item-1 .materials__block-border {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start; } }
