* {
  box-sizing: border-box;
  padding: 0;
  margin: 0; }


.materials {
  width: 100%;
  display: flex;
  align-items: stretch;
  height: 700px;
  color: #fff;

  .j-space {
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap; }
  &__row {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap; }
  &__block {
    padding: 10px;
    position: relative;
    overflow: hidden;
    width: calc(100% - 15px);
    height: calc(100% - 10px);
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: rgba(39, 157, 140, 0.6);
      transition: 0.3s; } }
  &__row-2 {
    width: 50%; }
  &__block:hover:before {
    background: rgba(39, 157, 140, 0.1); }
  &__block-border {
    border: 1px solid #fff;
    position: relative;
    z-index: 1;
    height: 100%;
    padding: 10px;
    font-size: 14px;
    a {
      color: #fff;
      text-decoration: none; }
    ul {
      margin-bottom: 15px;
      list-style: none;
      a {
        display: inline-block;
        margin-bottom: 5px; }
      a:before {
        content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNui8sowAAAAWdEVYdENyZWF0aW9uIFRpbWUAMDMvMTYvMjAFCOkTAAAALUlEQVQImV3MQQoAIAwDwWnx/1+OF8XinkKWpJI4BAXtUUdYNwyy7mRe9F/ABmDpCQrub9EFAAAAAElFTkSuQmCC');
        display: inline-block;
        margin-right: 3px;
        position: relative;
        top: -3px; } } }

  &__bg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    min-height: 100%;
    min-width: 100%; }
  &__item-2 .materials__block {}
  &__item-3 .materials__block {
    margin-bottom: 10px; }
  &__item-2 {}
  &__title {
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 15px;
    text-shadow: 1px 2px 1px #737373; }
  &__item-2,
  &__item-5 {
    height: 60%; }
  &__item-3,
  &__item-4 {
    height: 40%; }
  &__item-1 .materials__block-border {
    display: flex;
    justify-content: flex-end; } }

@import "main/responsive";
